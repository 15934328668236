@media only screen and (min-device-width: 768px) and (max-device-width: 1000px) {
  .filter {
    width: 500px;
  }

  .transfer {
    width: 300px;
    margin-top: 600px;
    margin-left: -125px;
  }

  .songTransfer {
    margin-right: 50px;
    margin-top: 150px;
  }
}
