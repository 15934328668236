@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  src: local('Lato'), url(./fonts/Roboto-Light.ttf) format('opentype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: italic;
  src: local('Lato'), url(./fonts/Roboto-LightItalic.ttf) format('opentype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: local('Lato'), url(./fonts/Roboto-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: italic;
  src: local('Lato'), url(./fonts/Roboto-Italic.ttf) format('opentype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: local('Lato'), url(./fonts/Roboto-Medium.ttf) format('opentype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: italic;
  src: local('Lato'), url(./fonts/Roboto-MediumItalic.ttf) format('opentype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  src: local('Lato'), url(./fonts/Roboto-Bold.ttf) format('opentype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: italic;
  src: local('Lato'), url(./fonts/Roboto-BoldItalic.ttf) format('opentype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  src: local('Lato'), url(./fonts/Roboto-Black.ttf) format('opentype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  font-style: italic;
  src: local('Lato'), url(./fonts/Roboto-BlackItalic.ttf) format('opentype');
}

body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Roboto';
}
/* Remove this when you upgrade antd */
.ant-card-actions {
  background-color: transparent;
}
.ant-form-item-control-input {
  width: 180px;
}
.adRequestForm .ant-form-item-control-input {
  width: 100%;
}
.drawer .ant-drawer-content-wrapper {
  min-width: 500px;
}
.inputValue .ant-form-item-control-input {
  width: 100%;
}
.detailInput .ant-form-item-control-input {
  width: 100%;
}
.songInput .ant-form-item-control-input {
  width: 100%;
  border-radius: 30px;
}
.buttonInput .ant-form-item-control-input {
  width: 100%;
}
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 760px) {
  .ant-table {
    width: 100%;
    overflow: hidden;
  }
  .drawer .ant-drawer-content-wrapper {
    min-width: 40%;
  }

  /* Force table to not be like tables anymore */
  .ant-table,
  .ant-thead,
  .ant-table-tbody,
  th,
  .ant-table-cell,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .ant-table-thead th {
    background-color: yellow !important;
  }

  tr {
    border: 1px solid #ccc;
    width: auto;
  }

  .ant-table-cell {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  .ant-table-cell:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    /* Label the data */
    content: attr(dataIndex);

    color: #000;
    font-weight: bold;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .ant-table {
    overflow-x: scroll;
    width: 100%;
  }
}
