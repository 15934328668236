@media only screen and (max-width: 760px), (max-device-width: 760px) {
  .leftImg {
    display: none;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .leftImg {
    display: none;
  }
  .card {
    width: 600px;
  }

  .createForm {
    width: 500px;
  }
  .createFormBackground {
    width: 600px;
  }
  .button-reset-save {
    margin-left: 20px;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
  .leftImg {
    display: none;
  }
  .card {
    width: 600px;
  }

  .createForm {
    width: 650px;
  }
  .createFormBackground {
    width: 850px;
  }
  .button-reset-save {
    margin-left: 92px;
  }
}
