.ant-table {
  margin-top: 10px;
}
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 760px) {
  .forbranch {
    width: 55%;
  }
  .forbranchbutton {
    width: 5vh;
  }
}
