@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 10;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background: #f28c28;
  overflow: hidden;
  z-index: -1;
}
.background li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.3);
  animation: animate 19s linear infinite;
}

.background li:nth-child(0) {
  left: 19%;
  width: 148px;
  height: 148px;
  bottom: -148px;
  animation-delay: -9s;
}
.background li:nth-child(1) {
  left: 12%;
  width: 123px;
  height: 123px;
  bottom: -123px;
  animation-delay: -6s;
}
.background li:nth-child(2) {
  left: 25%;
  width: 184px;
  height: 184px;
  bottom: -184px;
  animation-delay: 0s;
}
.background li:nth-child(3) {
  left: 72%;
  width: 198px;
  height: 198px;
  bottom: -198px;
  animation-delay: 3s;
}
.background li:nth-child(4) {
  left: 62%;
  width: 143px;
  height: 143px;
  bottom: -143px;
  animation-delay: -4s;
}
.background li:nth-child(5) {
  left: 32%;
  width: 158px;
  height: 158px;
  bottom: -158px;
  animation-delay: 6s;
}
.background li:nth-child(6) {
  left: 47%;
  width: 130px;
  height: 130px;
  bottom: -130px;
  animation-delay: 7s;
}
.background li:nth-child(7) {
  left: 2%;
  width: 166px;
  height: 166px;
  bottom: -166px;
  animation-delay: 6s;
}
.background li:nth-child(8) {
  left: 15%;
  width: 167px;
  height: 167px;
  bottom: -167px;
  animation-delay: -7s;
}
.background li:nth-child(9) {
  left: 33%;
  width: 101px;
  height: 101px;
  bottom: -101px;
  animation-delay: 10s;
}
