@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  svg:not(:root).svg-inline--fa {
    overflow: visible;
    width: 40px;
  }
  .p {
    font-size: 15px;
  }

  .songInfoBox {
    height: 300px;
    width: 250px;

    display: inline-block;
    color: '#fff';
    padding-bottom: 100px;
    margin-left: 40px;
    border-radius: 10;
    box-shadow: '0px 4px 15px 0px rgba(0,0,0,0.2)';
    background: 'linear-gradient(150deg, #42e695, #3bb2b8 100%)';
    box-sizing: content-box;
  }
  .adsInfoBox {
    height: 250px;
    width: 250px;

    color: '#fff';
    margin-top: 240px;
    display: inline-block;
    margin-left: -270px;
    border-radius: 10;
    box-shadow: '0px 4px 15px 0px rgba(0,0,0,0.2)';
    background: 'linear-gradient(150deg, #42e695, #3bb2b8 100%)';
  }
  .soundTypesInfoBox {
    height: 250px;
    width: 250px;

    display: inline-block;
    color: '#fff';
    margin-left: 170px;
    border-radius: 10;
    box-shadow: '0px 4px 15px 0px rgba(0,0,0,0.2)';
    background: 'linear-gradient(150deg, #42e695, #3bb2b8 100%)';
  }
  .totalInfoBox {
    height: 250px;
    width: 250px;

    color: '#fff';
    margin-top: 240px;
    display: inline-block;
    margin-left: -140px;
    border-radius: 10;
    box-shadow: '0px 4px 15px 0px rgba(0,0,0,0.2)';
    background: 'linear-gradient(150deg, #42e695, #3bb2b8 100%)';
  }

  .barChartRef {
    display: block;
    height: 350px;
    width: 600px;
    margin-left: 150px;
  }
  .pieChartRef {
    display: inline-block;
    height: 200px;
    width: 500px;
    margin-left: -150px;
    margin-top: 200px;
  }
  .canvas {
    width: 40vh;
  }
  .announcementChartRef {
    margin-left: 40px;
    border-radius: 10;
    height: 300;
    width: 250px;
    color: '#fff';

    display: inline-block;
    margin-left: 100px;
    border-radius: 10;
  }

  .promotionChartRef {
    height: 300;
    width: 250px;
    font-size: 16;
    display: inline-block;
    color: '#fff';
    margin-top: 315px;
    margin-left: -110px;
    border-radius: 10;
  }
  .jingleChartRef {
    height: 300;
    width: 250px;
    font-size: 16;
    color: '#fff';
    margin-top: 630px;
    display: inline-block;
    margin-left: -320px;
    border-radius: 10;
  }
  .allOnSameChartRef {
    height: 300;
    width: 250px;
    font-size: 16;
    color: '#fff';
    margin-top: 200px;
    display: inline-block;
    margin-left: -429px;
    border-radius: 10;
  }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
  .songInfoBox {
    height: 300px;
    width: 350px;

    display: inline-block;
    color: '#fff';
    padding-bottom: 100px;
    margin-left: 50px;
    border-radius: 10;
    box-shadow: '0px 4px 15px 0px rgba(0,0,0,0.2)';
    background: 'linear-gradient(150deg, #42e695, #3bb2b8 100%)';
    box-sizing: content-box;
  }
  .adsInfoBox {
    height: 250px;
    width: 350px;

    color: '#fff';
    margin-top: 240px;
    display: inline-block;
    margin-left: -390px;
    border-radius: 10;
    box-shadow: '0px 4px 15px 0px rgba(0,0,0,0.2)';
    background: 'linear-gradient(150deg, #42e695, #3bb2b8 100%)';
  }
  .soundTypesInfoBox {
    height: 250px;
    width: 350px;

    display: inline-block;
    color: '#fff';
    margin-left: 220px;
    border-radius: 10;
    box-shadow: '0px 4px 15px 0px rgba(0,0,0,0.2)';
    background: 'linear-gradient(150deg, #42e695, #3bb2b8 100%)';
  }
  .totalInfoBox {
    height: 250px;
    width: 350px;

    color: '#fff';
    margin-top: 240px;
    display: inline-block;
    margin-left: -220px;
    border-radius: 10;
    box-shadow: '0px 4px 15px 0px rgba(0,0,0,0.2)';
    background: 'linear-gradient(150deg, #42e695, #3bb2b8 100%)';
  }
  .canvas {
    width: 30vh;
  }
  .announcementChartRef {
    margin-left: 40px;
    border-radius: 10;
    height: 300;
    width: 250px;
    color: '#fff';
    display: inline-block;
    margin-left: 20px;
    border-radius: 10;
  }

  .promotionChartRef {
    height: 300;
    width: 250px;
    font-size: 16;
    display: inline-block;
    color: '#fff';
    margin-top: -1px;
    margin-left: 148px;
    border-radius: 10;
  }
  .jingleChartRef {
    height: 300;
    width: 250px;
    font-size: 16;
    color: '#fff';
    margin-top: 300px;
    display: inline-block;
    margin-left: -360px;
    border-radius: 10;
  }
}
