.events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
.notes-month {
  text-align: center;
  font-size: 28px;
}
.notes-month section {
  font-size: 28px;
}
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 760px) {
  .dashboardCalendar tr {
    width: 100% !important;
    display: grid;
    padding: 0;

    margin: 0;
  }
  .dashboardCalendar tbody {
    width: 14rem !important;
    display: block;

    padding: 0;
    margin: 0;
  }
  .dashboardCalendar
    .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-calendar-date {
    width: auto;
    height: auto;

    border: 0;
    border-top: 2px solid #f0f0f0;
    border-radius: 0;
  }
}
