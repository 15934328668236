.logo {
  height: 100%;
}
.headermenu {
  border-bottom: none;
}
.headerflagmenu > .ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  background: none;
}
.headerflagmenu {
  line-height: 10px;
}
.ant-menu-horizontal > .ant-menu-submenu-selected {
  background: none;
}
.ant-layout-header .site-layout-background {
  width: '100%';
}
.ant-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  border-radius: 2px;
  padding-top: 7px;
}
.site-layout-background {
  background: #fff;

  border-bottom: 3px solid #ff9404;
  padding-bottom: 10px;
}
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 760px) {
  .logo {
    width: 25%;
  }
}
