@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 760px) {
  .ant-calendar-picker-input.ant-input {
    border-radius: 3px;
    height: 53px;
  }
  .ant-picker-content {
    width: 200px;
    display: block;
  }
  .ant-picker-panel {
    padding: 5px;
    width: 310px;
  }
  .ant-picker-header {
    width: 175px;
  }
  .ant-picker-date-panel .ant-picker-body {
    padding: 0px 2px 3px 5px;
    width: 180px;
  }
  .ant-picker-time-panel {
    width: 167px;
  }
  .ant-picker-datetime-panel {
    width: 180px;
    background-color: #fff;
  }
  .ant-picker-time-panel .ant-picker-content {
    width: 101px;
  }
  .ant-picker-header-view {
    width: 80px;
    margin-left: -45px;
    text-align: center;
  }
  .ant-picker-time-panel {
    width: 164px;
  }
  .ant-picker-month-btn {
    float: left;
    margin-left: 42px;
  }
  .ant-picker-year-btn {
    margin-left: -2px;
    float: right;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1400px) {
  .ant-table {
    overflow-x: scroll;
    width: 100%;
  }
}
