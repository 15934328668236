@media only screen and (max-width: 760px), (max-device-width: 760px) {
  .leftImg {
    display: none;
  }
}

@media only screen and (max-width: 768px), (max-device-width: 1024px) {
  .leftImg {
    display: none;
  }
  .card {
    width: 600px;
  }
}
