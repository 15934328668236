/* .form-container {
  border: 0px solid;
  padding: 60px 60px;
  background: rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 3px 4px 26px 11px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: -1px 4px 26px 11px rgba(0, 0, 0, 0.6);
  box-shadow: -1px 4px 26px 11px rgba(0, 0, 0, 0.6);
  width: 550px;
}
.background {
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  width: 100%;
  height: 100vh;
}

.rememberme {
  color: #fff;
  float: left;
}
.forgotpassword {
  color: #fff;
  float: right;
  font-size: 12px;
}
.forgotInput .ant-form-item {
  position: relative;
}
.forgotInput .ant-form-item-control-input {
  width: 22rem;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
  .rememberme {
    color: #fff;
    float: left;
  }
  .forgotpassword {
    color: #fff;
    float: right;
    margin-right: -15px;
  }
  .forgotInput .ant-form-item-control-input {
    width: 15rem;
  }
} */
